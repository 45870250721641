import React from 'react'
import styled from 'styled-components'

import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'

const StyledContainer = styled(Container)`
  ${({theme}) => `
    background: #fff;
    padding-top: 54px;
    min-height: 100vh;
    
  `}
`

export default function Terms(props) {

  return (
    <StyledContainer fixed>
      <ol className="">
        <li>
          <Typography>
            Bayaran bulanan penggunaan adalah sebanyak <strong className="hard">RM 15.00</strong> bagi setiap 1 FB page
            yang diautomasikan.
          </Typography>
        </li>
        <li>
          <Typography>
            Kami akan berusaha untuk memastikan maklumat-maklumat anda selamat.
            Selain itu juga, Maklumat-maklumat yang diterima/diberi oleh anda tidak akan diberikan ke pihak ke-tiga
            melainkan sekiranya terdapat peruntukan undang-undang
            yang membolehkan data dan maklumat-maklumat diakses oleh pihak tertentu
          </Typography>
        </li>
        <li>
          <Typography>
            Kami akan memberikan tempoh penggunaan percuma selama 3 hari daripada tarikh anda daftar. Tempoh percubaan ini
            tidak boleh dipindah milik dan ditambah.
          </Typography>
        </li>
        <li>
          <Typography>
            Untuk masa sekarang, Bizsaya tidak menyediakan pembayaran secara automatik. Oleh itu, anda perlu membuat
            pembayaran sebelum akaun anda tidak aktif.
          </Typography>
        </li>
        <li>
          <Typography>
            Sekiranya akaun anda tidak diaktifkan selama seminggu. Akaun anda akan dibatalkan dan kami di Bizsaya
            berhak untuk memadam maklumat akaun anda daripada aplikasi.
          </Typography>
        </li>
        <li>
          <Typography>
            Anda boleh untuk keluar bila-bila masa. TIADA REFUND yang diberikan bagi baki masa yang tertinggal. Anda
            boleh aktifkan semula akaun bila-bila masa selagi akaun anda masih aktif.
          </Typography>
        </li>
        <li>
          <Typography>
            Sekiranya akaun anda telah tidak aktif, dan anda ingin menggunakan kembali Bizsaya, tiada tempoh
            percubaan (Trial) yang akan diberikan kepada anda semula.
          </Typography>
        </li>
        <li>
          <Typography>
            Kami akan menyediakan aplikasi Bizsaya as-it-is. Akan tetapi, kami akan mencuba untuk memberikan servis
            yang terbaik buat automasi FB page anda.
          </Typography>
        </li>
        <li>
          <Typography>
            Kami tidak akan bertanggungjawab sekiranya terdapat aktiviti yang mendorong kepada pengantungan FB page
            anda akibat daripada salah guna mahupun ralat aplikasi Bizsaya.
          </Typography>
        </li>
        <li>
          <Typography>
            Anda juga perlu bertanggungjawab atas salah guna yang berkemungkinan timbul sekiranya aplikasi ini
            disalah gunakan seperti spamming, contents dan yang sama dengannya.
          </Typography>
        </li>
        <li>
          <Typography>
            Kami berhak untuk memadam akaun anda tanpa sebarang notis sekiranya didapati terdapat aktiviti-aktiviti
            yang menyalahi undang-undang negara atau menyalahi undang-undang/terma/polisi Facebook ataupun salah guna
            aplikasi.
          </Typography>
        </li>
        <li>
          <Typography>
            Bizsaya dilengkapi dengan teknologi YOBB yang boleh mengesan sekiranya customer anda memberikan reaksi negatif
            kepada page anda.
            Sekiranya page anda kerap menerima reaksi negatif, Kami berhak membatalkan akaun anda.
          </Typography>
        </li>
        <li>
          <Typography>
            TIADA REFUND akan diberikan sekiranya akaun anda dipadam tanpa notis akibat daripada terma (11) dan (12).
          </Typography>
        </li>
        <li>
          <Typography>
            Tiada maklumat yang boleh didapatkan semula sekiranya akaun anda telah dipadamkan daripada aplikasi
            akibat daripada terma (5), terma (11) dan terma (12).
          </Typography>
        </li>
        <li>
          <Typography>
            Bizsaya berhak untuk mengubah polisi pada bila-bila masa. Kami akan memaklumkan perubahan polisi pada group
            whatsapp support Bizsaya.
          </Typography>
        </li>
        <li>
          <Typography>
            Bantuan aplikasi Bizsaya adalah tertaklu kepada keboleh beradaan team aplikasi Bizsaya. Kami akan cuba untuk
            bantu anda secepat mungkin.
          </Typography>
        </li>
        <li>
          <Typography>
            Kami tidak memberikan sebarang jaminan up-time availabiliti (SLA) kerana aplikasi Bizsaya merupakan sebuah
            aplikasi pihak ke-tiga Facebook.
          </Typography>
        </li>
        <li>
          <Typography>
            Sekiranya aplikasi Bizsaya tergangu atas faktor-faktor yang diluar kemampuan kami. Gantian tempoh masa yang
            tergangu adalah tertakluk kepada budi bicara kami.
          </Typography>
        </li>
      </ol>
    </StyledContainer>
  )
}
