import React, {useEffect, useRef, useState} from 'react'
import styled from 'styled-components'
import axios from 'axios'
import {isEmpty, trim} from 'lodash'
import {Link} from 'react-router-dom'

import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'

import ExpiredDialog from './expired-dialog'
// import Wave from './wave'

const StyledDiv = styled.div`
  ${({theme}) => `
    padding-top: 54px;
        
    > .content {
      position: relative;
      z-index: 1;
      min-height: calc(100vh - 54px);
      display: flex;
      
      > div > div {
        height: 100%;
        flex-wrap: nowrap;
        
        h2 {
          margin-left: -5px;
        }
        
        @media (max-width: 600px) {
          flex-wrap: wrap;
          margin: 0px;
          width: 100%;
        }
      
        > div {
          &:first-child {
            flex: 1 1 auto;
            
            @media (max-width: 600px) {
              margin-top: 30px;
              text-align: justify;
              min-height: calc(100vh - 54px - 38px - 0px);
              display: flex;
              align-items: center;
              justify-content: center;
              flex-direction: column;
              
              h2 {
                margin-bottom: 16px;
              }
            }
          }
          
          &:last-child {
            > div {
              padding: 16px; 
              max-height: 380px;
              overflow: hidden;
            }
          }
        }
      }
      
      .card {
        background-image: url("/fabric_plaid.png");
        min-width: 380px;
        max-width: 380px;
        max-height: 380px;
        
        @media (max-width: 600px) {
          min-width: 180px;
          margin-bottom: 90px;
        }
            
        .title-login {
          margin-bottom: 24px;
        }    
      
        .caption {
          margin-bottom: 16px;
        }
        
        .error {
          margin-bottom: 16px;
          width: 100%;
          border: 1px solid #f50057;
          padding: 8px 16px;
        }
        
        #login-container {
          position: relative;
          
          .overlay {
            position: absolute;
            width: 100%;
            height: 40px;
            z-index: 300;
            cursor: pointer;
          }
        }
        
        .terms {
          margin-top: 24px;
          text-align: right;
          
          a {
            text-decoration: none;
          }
        }
      }  
     
    }
  `}
`

export default function Home(props) {

  const [expired, setExpired] = useState(false)
  const [detail, setDetail] = useState({})
  const [errMsg, setErrMsg] = useState('')

  const timeout = useRef()

  useEffect(() => {
    let baseURL = 'https://api.bizsaya.com/'
    let appUrl = 'https://app.bizsaya.com'

    const {hostname} = window.location

    if (hostname === 'localhost' || hostname === 'dev.locate.com') {
      baseURL = 'http://localhost:3000/'
      appUrl = hostname === 'localhost' ? 'https://localhost:4000' : 'https://dev.locate.com:4000'
    } else if (hostname === 'portal.bizsaya.com') {
      baseURL = 'https://api2.bizsaya.com/'
      appUrl = 'https://app2.bizsaya.com'
    }

    // document.body.style.backgroundImage = 'url("/dot-grid.png")'
    
    if (!_.isEmpty(_.trim(window.location.search))) {
      const qs = {}
      window.location.search.replace(/^\?/, '').split('&').forEach(param => {
        const query = param.split('=')
        if (!_.isEmpty(query) && query.length === 2) {
          qs[query[0]] = query[1]
        }
      })

      if (!_.isEmpty(_.trim(qs.code))) {
        const {protocol, host} = window.location
        const redirect = `${protocol}//${host}/`

        axios.post(`${baseURL}auth`, {code: qs.code, url: redirect})
          .then(response => {
            window.location.href = `${appUrl}?token=${response.data.token}`
          })
          .catch(err => {
            showLoginBtn()
            if (err.response && err.response.status === 402) {
              const {msg, ...others} = err.response.data
              setDetail(others)
              setExpired(true)
            } else if (err.response && err.response.status === 400) {
              setErrMsg(err.response.data.msg)
            } else {
              setErrMsg('Ralat pada sistem Bizsaya')
            }
          })
      } else {
        showLoginBtn()
      }
    } else {
      showLoginBtn()
    }

  }, [])

  function showLoginBtn () {
    timeout.current = setTimeout(() => {
      const element = document.getElementById('login-container')
      if (element) {
        FB.XFBML.parse(element)
      }
    }, 600)
  }

  function manualLogin () {
    const {protocol, host} = window.location
    const redirect = `${protocol}//${host}/`

    window.location.href = `https://www.facebook.com/v9.0/dialog/oauth?client_id=3046567165580925&redirect_uri=${redirect}&scope=public_profile,email`
  }

  return (
    <StyledDiv>
      <Box className={'content'}>
        <Container>
          <Grid container spacing={2} wrap={'wrap'} alignItems={'center'} justify={'center'}>
            <Grid item xs={12} sm className={'typehead'}>
              <Typography variant={'h2'}>
                Bizsaya
              </Typography>
              <Typography variant={'body2'}>
                Sebuah sistem yang digunakan untuk membantu menguruskan laman sosial perniagaan anda dengan
                kos yang berbaloi serta mudah untuk digunakan.
                Selain itu juga, Bizsaya akan "capture" nombor telefon dari laman sosial perniagaan anda dan
                "foward" terus kepada anda dengan mudah dan "Realtime"
              </Typography>
            </Grid>
            <Grid item xs sm={6}>
              <Card elevation={4} className={'card'}>
                <Typography variant={'h5'} color={'primary'} className={'title-login'}>
                  Log masuk
                </Typography>

                <Typography className={'caption'} component={'div'}>
                  Klik pada butang dibawah untuk
                  log masuk ataupun daftar akaun di Bizsaya.
                </Typography>

                {
                  !_.isEmpty(_.trim(errMsg)) && (
                    <Typography className={'error'} component={'div'} color={"secondary"}>
                      {errMsg}
                    </Typography>
                  )
                }

                <Grid container alignItems={'center'} justify={'center'}>
                  <Grid item id={'login-container'}>
                    <Box className={'overlay'} onClick={() => manualLogin()}></Box>
                    <div
                      className="fb-login-button"
                      data-size="large"
                      data-button-type="login_with"
                      data-layout="default"
                      data-auto-logout-link="false"
                      data-use-continue-as="true"
                      data-scope="public_profile,email"
                      data-onlogin="checkLoginState();"
                    >
                    </div>
                  </Grid>
                </Grid>

                <Box className={'terms'}>
                  <Typography variant={'caption'}>
                    <Link to={'/terms'}>Terma</Link>
                    &nbsp;&nbsp;-&nbsp;&nbsp;
                    <Link to={'/policy'}>Polisi</Link>
                    &nbsp;&nbsp;-&nbsp;&nbsp;
                    <Link to={'/deletion'}>Padam data</Link>
                  </Typography>
                </Box>

              </Card>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/*<Wave />*/}

      {
        expired && <ExpiredDialog detail={detail} onClose={() => {setExpired(false); setDetail({})}} />
      }
    </StyledDiv>
  )
}
